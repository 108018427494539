import { createAction } from "@reduxjs/toolkit";
import { api, axiosClient } from ".";
import {
    ICreateDeliverySlip,
    IDeliveryBill,
    IFilterDeliverySlip,
    IFilterWaitingList,
    IUpdateDeliveryBill,
    IStatusDeliveryBill,
} from "../models/deliveryslip.model";
import { IPagination } from "../models/paginations.model";
import { ITracking } from "../models/trackings.model";
import axios from "axios";

export const successToastAction = createAction<string>("toast/success");
export const failedToastAction = createAction<string>("toast/failed");

const deliverySlipApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDeliverySlipList: build.query<
            {
                data: IDeliveryBill[];
                meta: IPagination;
            },
            IFilterDeliverySlip
        >({
            query: (params) => ({
                url: "api/delivery_bills",
                method: "GET",
                params,
            }),
            providesTags: [{ type: "DeliverySlip", id: "LIST" }],
        }),
        getWaitingList: build.query<
            {
                data: any[];
                pagination: IPagination;
            },
            IFilterWaitingList
        >({
            query: (params) => ({
                url: "api/delivery_bills/waiting_create_delivery_bills",
                method: "GET",
                params,
            }),
            providesTags: [{ type: "DeliverySlip", id: "WAITING" }],
        }),
        createDeliverySlip: build.mutation<any, ICreateDeliverySlip>({
            query: (data) => ({
                url: `api/delivery_bills`,
                method: "POST",
                data,
            }),
            invalidatesTags: [
                { type: "DeliverySlip", id: "LIST" },
                { type: "DeliverySlip", id: "WAITING" },
            ],
        }),
        getDeliveryBillDetail: build.query<any, number>({
            query: (id) => ({
                url: `api/delivery_bills/${id}`,
                method: "GET",
            }),
            providesTags: [{ type: "DeliverySlip", id: "DETAIL" }],
        }),
        deliveryBillSearch: build.query<
            {
                data: IDeliveryBill[];
            },
            { keyword: string }
        >({
            query: (params) => ({
                url: `api/delivery_bills/search`,
                method: "GET",
                params,
            }),
        }),
        getListBillStatus: build.query<any, void>({
            query: () => ({
                url: `api/delivery_bills/status_update_bill`,
                method: "GET",
            }),
        }),
        updateDeliveryBill: build.mutation<
            any,
            IUpdateDeliveryBill & { id: number }
        >({
            query: ({ id, ...data }) => ({
                url: `api/delivery_bills/${id}`,
                method: "PUT",
                data: data,
            }),
            invalidatesTags: [
                { type: "DeliverySlip", id: "LIST" },
                { type: "DeliverySlip", id: "DETAIL" },
            ],
        }),
        getDeliveryBillByCustomer: build.query<
            {
                data: IDeliveryBill[];
                pagination: IPagination;
            },
            any
        >({
            query: ({ id, ...params }) => ({
                url: `api/delivery_bills/${id}/bill_customer_detail`,
                method: "GET",
                params,
            }),
        }),
        getTrackingDeliveryBillByCustomer: build.query<
            {
                data: ITracking[];
            },
            any
        >({
            query: ({ id, ...params }) => ({
                url: `api/delivery_bills/${id}/tracking_customer_detail`,
                method: "GET",
                params,
            }),
            providesTags: [
                { type: "DeliverySlip", id: "LIST_TRACKING_CUSTOMER" },
            ],
        }),
        getTrackingByCode: build.query<
            {
                data: ITracking;
            },
            any
        >({
            query: ({ id, ...params }) => ({
                url: `api/delivery_bills/${id}/search_tracking`,
                method: "GET",
                params,
            }),
        }),
        approveDeliveryBill: build.mutation<any, any>({
            query: (id) => ({
                url: `api/delivery_bills/${id}/sale_approve_delivery_bills`,
                method: "PUT",
            }),
            invalidatesTags: [
                { type: "DeliverySlip", id: "LIST" },
                { type: "DeliverySlip", id: "DETAIL" },
            ],
        }),
        accountantApproveDeliveryBill: build.mutation<any, any>({
            query: (id) => ({
                url: `api/delivery_bills/${id}/accountant_approve_delivery_bills`,
                method: "PUT",
            }),
            invalidatesTags: [
                { type: "DeliverySlip", id: "LIST" },
                { type: "DeliverySlip", id: "DETAIL" },
            ],
        }),
        printDeliveryBill: build.mutation<any, any>({
            query: (id) => ({
                url: `api/delivery_bills/${id}/print_delivery_bills`,
                method: "PUT",
            }),
            invalidatesTags: [
                { type: "DeliverySlip", id: "LIST" },
                { type: "DeliverySlip", id: "DETAIL" },
            ],
        }),
        exportDeliveryBill: build.mutation<any, any>({
            query: ({ id, ...data }) => ({
                url: `api/delivery_bills/${id}/export_delivery_bills`,
                method: "PUT",
                data,
            }),
            invalidatesTags: [
                { type: "DeliverySlip", id: "LIST" },
                { type: "DeliverySlip", id: "DETAIL" },
                { type: "Tracking", id: "DETAIL" },
                { type: "Tracking", id: "LIST" },
                { type: "Order", id: "DETAIL" },
                { type: "Order", id: "LIST" },
            ],
        }),
        packDeliveryBill: build.mutation<any, any>({
            query: (id) => ({
                url: `api/delivery_bills/${id}/pack_delivery_bills`,
                method: "PUT",
            }),
            invalidatesTags: [
                { type: "DeliverySlip", id: "LIST" },
                { type: "DeliverySlip", id: "DETAIL" },
            ],
        }),
        finishDeliveryBill: build.mutation<any, any>({
            query: (id) => ({
                url: `api/delivery_bills/${id}/finish_delivery_bills`,
                method: "PUT",
            }),
            invalidatesTags: [
                { type: "DeliverySlip", id: "LIST" },
                { type: "DeliverySlip", id: "DETAIL" },
            ],
        }),
        failedDeliveryBill: build.mutation<any, any>({
            query: (id) => ({
                url: `api/delivery_bills/${id}/failed_delivery_bills`,
                method: "PUT",
            }),
            invalidatesTags: [
                { type: "DeliverySlip", id: "LIST" },
                { type: "DeliverySlip", id: "DETAIL" },
            ],
        }),
        cancelDeliveryBill: build.mutation<any, any>({
            query: (id) => ({
                url: `api/delivery_bills/${id}/cancel_delivery_bills`,
                method: "PUT",
            }),
            invalidatesTags: [
                { type: "DeliverySlip", id: "LIST" },
                { type: "DeliverySlip", id: "DETAIL" },
            ],
        }),
        addManyTrackingToBill: build.mutation<any, any>({
            query: ({ id, ...data }) => ({
                url: `api/delivery_bills/${id}/create_many_tracking_in_bill`,
                method: "POST",
                data,
            }),
            invalidatesTags: [
                { type: "DeliverySlip", id: "LIST" },
                { type: "DeliverySlip", id: "WAITING" },
                { type: "DeliverySlip", id: "DETAIL" },
            ],
        }),
        packTrackingInBill: build.mutation<any, any>({
            query: ({ id, ...data }) => ({
                url: `api/delivery_bills/${id}/pack_tracking_in_bill`,
                method: "PUT",
                data,
            }),
            invalidatesTags: [{ type: "DeliverySlip", id: "DETAIL" }],
        }),
        deleteTrackingToBill: build.mutation<any, any>({
            query: ({ id, ...data }) => ({
                url: `api/delivery_bills/${id}/delete_tracking_in_bill`,
                method: "DELETE",
                data,
            }),
            invalidatesTags: [
                { type: "DeliverySlip", id: "LIST" },
                { type: "DeliverySlip", id: "DETAIL" },
            ],
        }),
        skipApproveDeliveryBill: build.mutation<any, any>({
            query: (id) => ({
                url: `api/delivery_bills/${id}/skip_approval`,
                method: "PUT",
            }),
            invalidatesTags: [
                { type: "DeliverySlip", id: "LIST" },
                { type: "DeliverySlip", id: "DETAIL" },
            ],
        }),
        getStatusDeliveryBill: build.query<
            {
                data: IStatusDeliveryBill[];
            },
            IFilterDeliverySlip
        >({
            query: (params) => ({
                url: "api/delivery_bills/status",
                method: "GET",
                params,
            }),
            providesTags: [{ type: "DeliverySlip", id: "LIST" }],
        }),
        assignShipper: build.mutation<
            unknown,
            {
                billId: number;
                shipperId: number;
            }
        >({
            query: (params) => ({
                url: `api/delivery_bills/${params.billId}/assign_shipper`,
                method: "PUT",
                data: { deliveredById: params.shipperId },
            }),
        }),
    }),
});

// write above but with axios

// Sale duyệt

export const approveDeliveryBill = async (id: number) => {
    const response = await axiosClient.put(
        `api/delivery_bills/${id}/sale_approve_delivery_bills`,
    );
    return response.data;
};

// Kế toán duyệt

export const accountantApproveDeliveryBill = async (id: number) => {
    const response = await axiosClient.put(
        `api/delivery_bills/${id}/accountant_approve_delivery_bills`,
    );
    return response.data;
};

// Đóng gói

export const packDeliveryBill = async (id: number) => {
    const response = await axiosClient.put(
        `api/delivery_bills/${id}/pack_delivery_bills`,
    );
    return response.data;
};

// Hoàn thành

export const finishDeliveryBill = async (id: number) => {
    const response = await axiosClient.put(
        `api/delivery_bills/${id}/finish_delivery_bills`,
    );
    return response.data;
};

// Thất bại

export const failedDeliveryBill = async (id: number) => {
    const response = await axiosClient.put(
        `api/delivery_bills/${id}/failed_delivery_bills`,
    );
    return response.data;
};

// Hủy

export const cancelDeliveryBill = async (id: number) => {
    const response = await axiosClient.put(
        `api/delivery_bills/${id}/cancel_delivery_bills`,
    );
    return response.data;
};

// Yêu cầu xuất kho

export const skipApproveDeliveryBill = async (id: number) => {
    const response = await axiosClient.put(
        `api/delivery_bills/${id}/skip_approval`,
    );
    return response.data;
};

// Khách hàng xác nhận
export const printDeliveryBill = async (id: number) => {
    const response = await axiosClient.put(
        `api/delivery_bills/${id}/print_delivery_bills`,
    );
    return response.data;
};

export const {
    useGetDeliverySlipListQuery,
    useGetWaitingListQuery,
    useCreateDeliverySlipMutation,
    useGetDeliveryBillDetailQuery,
    useGetListBillStatusQuery,
    useUpdateDeliveryBillMutation,
    useGetDeliveryBillByCustomerQuery,
    useGetTrackingDeliveryBillByCustomerQuery,
    useGetTrackingByCodeQuery,
    useApproveDeliveryBillMutation,
    usePrintDeliveryBillMutation,
    useExportDeliveryBillMutation,
    useCancelDeliveryBillMutation,
    useAddManyTrackingToBillMutation,
    useDeleteTrackingToBillMutation,
    useAccountantApproveDeliveryBillMutation,
    useFinishDeliveryBillMutation,
    useFailedDeliveryBillMutation,
    usePackDeliveryBillMutation,
    useDeliveryBillSearchQuery,
    usePackTrackingInBillMutation,
    useSkipApproveDeliveryBillMutation,
    useGetStatusDeliveryBillQuery,
    useAssignShipperMutation,
} = deliverySlipApi;
