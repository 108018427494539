import axios from "axios";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import config from "./config";
import { store } from "./redux/store";
import { getAccessToken } from "./utils/getAccessToken";
import { getRefreshToken } from "./utils/getRefreshToken";
import jwtDecode from "jwt-decode";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import { logoutUser } from "./utils/authUtils";

// Cai dat baseURL mac dinh cho cac request su dung axios
axios.defaults.baseURL = config.API_URL;
// xu ly response tra ve, neu response code = 401, trang thai chua dang nhap, thi thuc hien refresh token
axios.interceptors.response.use(
	(response) => {
		return response;
	},
	async (err) => {
		return new Promise((resolve, reject) => {
			const originalReq = err.config;
			const cookies = new Cookies();
			const remember = cookies.get("rememberAccount");
			if (
				err.response.status === 401 &&
				err.config &&
				!err.config.__isRetryRequest &&
				err.response.data.message === "TokenExpiredError"
			) {
				if (remember === "true") {
					let res = fetch(
						axios.defaults.baseURL + "/api/auth/refresh-token",
						{
							method: "POST",
							mode: "cors",
							cache: "no-cache",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${getAccessToken()}`,
							},
							body: JSON.stringify({
								refreshToken: getRefreshToken(),
							}),
							redirect: "follow",
							referrer: "no-referrer",
						},
					)
						.then((res) => res.json())
						.then((res) => {
							let cookies = new Cookies();
							if (res.token) {
								cookies.set("access_token", res.token, {
									path: "/",
								});
								cookies.set("user", jwtDecode(res.token), {
									path: "/",
								});
								cookies.set("refresh_token", res.refreshToken, {
									path: "/",
								});
							}
							originalReq.headers["Authorization"] =
								`Bearer ${res.token}`;
							return axios(originalReq);
						})
						.catch((error) => console.log(error));
					resolve(res);
				} else {
					window.history.pushState(null, "", `/auth/login`);
					logoutUser();
				}
			} else if (
				err.response.status === 403 &&
				err.response.data.message === "Permission denied"
			) {
				toast.error("Bạn không có quyền thực hiện chức năng này!");
			} else {
				throw err;
			}
			reject(err);
		});
	},
);

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root"),
);
